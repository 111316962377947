var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("stepNav", {
        attrs: { name: "Alternativ", validStep: _vm.stepIsValid },
      }),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("radioButtonGroup", {
            attrs: {
              label: _vm.$t("alternatives.accessory_color"),
              options: _vm.accessoryColors,
            },
            model: {
              value: _vm.accessoryColor,
              callback: function ($$v) {
                _vm.accessoryColor = $$v
              },
              expression: "accessoryColor",
            },
          }),
        ],
        1
      ),
      _vm.selectedSnowProtection
        ? _c(
            "div",
            { staticClass: "form-group" },
            [
              _c(
                "b-field",
                { attrs: { label: _vm.$t("aternatives.roof_angle") } },
                [
                  _c("b-input", {
                    attrs: {
                      type: "number",
                      min: _vm.roofTiltOptions.min,
                      max: _vm.roofTiltOptions.max,
                      step: _vm.roofTiltOptions.step,
                      "icon-right": undefined,
                      expanded: "",
                    },
                    model: {
                      value: _vm.roofTilt,
                      callback: function ($$v) {
                        _vm.roofTilt = $$v
                      },
                      expression: "roofTilt",
                    },
                  }),
                  _c("span", { staticClass: "input-suffix" }, [_vm._v("°")]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.selectedSnowProtection
        ? _c(
            "div",
            { staticClass: "form-group" },
            [
              _c(
                "b-field",
                {
                  attrs: {
                    label: _vm.$t("alternatives.roof_pitch"),
                    append: "m",
                  },
                },
                [
                  _c("b-input", {
                    attrs: {
                      type: "number",
                      min: _vm.roofLengthOptions.min,
                      max: _vm.roofLengthOptions.max,
                      step: _vm.roofLengthOptions.step,
                      expanded: "",
                    },
                    model: {
                      value: _vm.roofLength,
                      callback: function ($$v) {
                        _vm.roofLength = $$v
                      },
                      expression: "roofLength",
                    },
                  }),
                  _c("span", { staticClass: "input-suffix" }, [_vm._v("m")]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.selectedSnowProtection
        ? _c(
            "div",
            { staticClass: "form-group" },
            [
              _vm.selectedSnowProtection
                ? _c("radioButtonGroup", {
                    attrs: {
                      label: _vm.$t("alternatives.snow_zone"),
                      options: _vm.snowZones,
                    },
                    model: {
                      value: _vm.snowZone,
                      callback: function ($$v) {
                        _vm.snowZone = $$v
                      },
                      expression: "snowZone",
                    },
                  })
                : _vm._e(),
              _c("snowZoneMap"),
            ],
            1
          )
        : _vm._e(),
      _vm.selectedSnowProtection && _vm.distanceConsole
        ? _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t("alternatives.distance_between_consoles"),
              },
            },
            [
              _c("div", [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.distanceConsole
                        ? _vm.$t(
                            "alternatives.calculted_distance_between_consoles",
                            { distance: _vm.distanceConsole }
                          )
                        : null
                    ) +
                    " "
                ),
                _c("br"),
                _vm.rowsSnowprotection > 1
                  ? _c("span", { staticClass: "form-group is-warning" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("alternatives.snow_zone_warning", {
                              multiplier: _vm.rowsSnowprotection,
                            })
                          ) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }