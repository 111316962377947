var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("div", { staticClass: "buttons" }, [
        _c(
          "button",
          {
            staticClass: "button link",
            attrs: { id: "snow-zone-button" },
            on: {
              click: function ($event) {
                _vm.isModalActive = true
              },
            },
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("snow_zone.find_snowzone")) + "\n    "
            ),
          ]
        ),
      ]),
      _c(
        "b-modal",
        {
          attrs: { "custom-class": "center-content" },
          model: {
            value: _vm.isModalActive,
            callback: function ($$v) {
              _vm.isModalActive = $$v
            },
            expression: "isModalActive",
          },
        },
        [
          _c(
            "div",
            { style: { width: _vm.windowWidth + "px", margin: "auto" } },
            [
              _c("table", { staticStyle: { width: "100%" } }, [
                _c("tr", [
                  _c("th", [_vm._v("1")]),
                  _c("th", [_vm._v("1,5")]),
                  _c("th", [_vm._v("2")]),
                  _c("th", [_vm._v("2,5")]),
                  _c("th", [_vm._v("3")]),
                  _c("th", [_vm._v("3,5")]),
                  _c("th", [_vm._v("4,5")]),
                  _c("th", [_vm._v("5,5")]),
                ]),
                _c("tr", [
                  _c("td", [
                    _c("img", {
                      staticStyle: { opacity: "1" },
                      attrs: {
                        src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAADFJREFUOI1jYaAyYKGZgTWx5v8pMahl8UlGFAOpBUYNHDVw1MBRA+lsIKw8o5qB1AIAWhUGQpldFqQAAAAASUVORK5CYII=",
                        border: "0",
                      },
                    }),
                  ]),
                  _c("td", [
                    _c("img", {
                      staticStyle: { opacity: "1" },
                      attrs: {
                        src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAADFJREFUOI1jYaAyYKGZgUf3bfhPiUHWTgGMKAZSC4waOGrgqIGjBtLZQFh5RjUDqQUA9i4FH6Ewx7EAAAAASUVORK5CYII=",
                        border: "0",
                      },
                    }),
                  ]),
                  _c("td", [
                    _c("img", {
                      staticStyle: { opacity: "1" },
                      attrs: {
                        src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAADFJREFUOI1jYaAyYKGZgRfvt/6nxCB9xWpGFAOpBUYNHDVw1MBRA+lsIKw8o5qB1AIA9UcFHf5GQZoAAAAASUVORK5CYII=",
                        border: "0",
                      },
                    }),
                  ]),
                  _c("td", [
                    _c("img", {
                      staticStyle: { opacity: "1" },
                      attrs: {
                        src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAADFJREFUOI1jYaAyYKGZgZU71P9TYlC7x01GFAOpBUYNHDVw1MBRA+lsIKw8o5qB1AIAQUgF+hUaw2QAAAAASUVORK5CYII=",
                        border: "0",
                      },
                    }),
                  ]),
                  _c("td", [
                    _c("img", {
                      staticStyle: { opacity: "1" },
                      attrs: {
                        src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAADFJREFUOI1jYaAyYKGZgdtO3/hPiUFephqMKAZSC4waOGrgqIGjBtLZQFh5RjUDqQUA6VUE+QFFMBsAAAAASUVORK5CYII=",
                        border: "0",
                      },
                    }),
                  ]),
                  _c("td", [
                    _c("img", {
                      staticStyle: { opacity: "1" },
                      attrs: {
                        src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAADFJREFUOI1jYaAyYKGZgW1z1vynxKCqlBBGFAOpBUYNHDVw1MBRA+lsIKw8o5qB1AIAGTAFhNI9aygAAAAASUVORK5CYII=",
                        border: "0",
                      },
                    }),
                  ]),
                  _c("td", [
                    _c("img", {
                      staticStyle: { opacity: "1" },
                      attrs: {
                        src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAADFJREFUOI1jYaAyYKGZge7ptf8pMWjnzGZGFAOpBUYNHDVw1MBRA+lsIKw8o5qB1AIAUUgGJwhKbykAAAAASUVORK5CYII=",
                        border: "0",
                      },
                    }),
                  ]),
                  _c("td", [
                    _c("img", {
                      staticStyle: { opacity: "1" },
                      attrs: {
                        src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAC9JREFUOI1jYaAyYKGdgebR/yky6eRSRlQDqQRGDRw1cNTAUQPpbSC0PKOegVQCAIOrBMC4k/D+AAAAAElFTkSuQmCC",
                        border: "0",
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("iframe", {
                attrs: {
                  src: "https://gis2.boverket.se/portal/apps/webappviewer/index.html?id=b2c96e9a357b4088bd1ec7e934e0296b",
                  title: "SnowZone Map",
                  height: _vm.windowHeight,
                  width: _vm.windowWidth,
                  scrolling: "no",
                },
              }),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }