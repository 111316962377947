<template>
    <section>
      <div class="buttons">

        <button id="snow-zone-button" class="button link"
          @click="isModalActive = true">
          {{ $t('snow_zone.find_snowzone') }}
        </button>
      </div>
        <b-modal v-model="isModalActive" custom-class="center-content">
          <div :style="{ width: windowWidth + 'px', margin: 'auto' }">
          <table style="width:100%">
            <tr>
              <th>1</th>
              <th>1,5</th>
              <th>2</th>
              <th>2,5</th>
              <th>3</th>
              <th>3,5</th>
              <th>4,5</th>
              <th>5,5</th>
            </tr>
            <tr>
              <td><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAADFJREFUOI1jYaAyYKGZgTWx5v8pMahl8UlGFAOpBUYNHDVw1MBRA+lsIKw8o5qB1AIAWhUGQpldFqQAAAAASUVORK5CYII=" border="0" style="opacity:1"></td>
              <td><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAADFJREFUOI1jYaAyYKGZgUf3bfhPiUHWTgGMKAZSC4waOGrgqIGjBtLZQFh5RjUDqQUA9i4FH6Ewx7EAAAAASUVORK5CYII=" border="0" style="opacity:1"></td>
              <td><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAADFJREFUOI1jYaAyYKGZgRfvt/6nxCB9xWpGFAOpBUYNHDVw1MBRA+lsIKw8o5qB1AIA9UcFHf5GQZoAAAAASUVORK5CYII=" border="0" style="opacity:1"></td>
              <td><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAADFJREFUOI1jYaAyYKGZgZU71P9TYlC7x01GFAOpBUYNHDVw1MBRA+lsIKw8o5qB1AIAQUgF+hUaw2QAAAAASUVORK5CYII=" border="0" style="opacity:1"></td>
              <td><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAADFJREFUOI1jYaAyYKGZgdtO3/hPiUFephqMKAZSC4waOGrgqIGjBtLZQFh5RjUDqQUA6VUE+QFFMBsAAAAASUVORK5CYII=" border="0" style="opacity:1"></td>
              <td><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAADFJREFUOI1jYaAyYKGZgW1z1vynxKCqlBBGFAOpBUYNHDVw1MBRA+lsIKw8o5qB1AIAGTAFhNI9aygAAAAASUVORK5CYII=" border="0" style="opacity:1"></td>
              <td><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAADFJREFUOI1jYaAyYKGZge7ptf8pMWjnzGZGFAOpBUYNHDVw1MBRA+lsIKw8o5qB1AIAUUgGJwhKbykAAAAASUVORK5CYII=" border="0" style="opacity:1"></td>
              <td><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAC9JREFUOI1jYaAyYKGdgebR/yky6eRSRlQDqQRGDRw1cNTAUQPpbSC0PKOegVQCAIOrBMC4k/D+AAAAAElFTkSuQmCC" border="0" style="opacity:1"></td>
            </tr>
          </table>
          <iframe 
            src="https://gis2.boverket.se/portal/apps/webappviewer/index.html?id=b2c96e9a357b4088bd1ec7e934e0296b"
            title="SnowZone Map" 
            :height="windowHeight" 
            :width="windowWidth"
            scrolling="no"
          ></iframe>
          </div>
        </b-modal>
    </section>
</template>

<script>
export default {
  data() {
    return {
      isModalActive: false,
      windowHeight: 0,
      windowWidth: 0
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
      this.onResize()
    })
  },

  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },

  methods: {  
    onResize() {
      this.windowHeight = window.innerHeight - 220
      this.windowWidth = window.innerWidth > 960 ? '960': window.innerWidth*0.8
    }
  }
}
</script>

<style scoped>
.center-content {
  margin: auto;
}
table {
  background-color: rgb(118,118,118);
}
.link {
  color: #a6192e;
  margin: auto;
}
#snow-zone-button {
  padding-top: 0px;
}
</style>
